import {
  GetNextAppointmentRequest,
  GetNextAppointmentResponse,
  GetNextAppointmentUrl,
} from '@global/utils/api/appointment.controller.model';
import { NativeConfigWebviewExtraDataTelehealthInfo } from '@global/utils/native/native-config';
import { CustomWebviewMessageForTeleatendimentoInicio } from '@global/utils/native/native-custom-webview-message';
import { GradientBackground } from '@lp-root/src/components/atm.background/background.styled';
import { GatsbyButton } from '@lp-root/src/components/atm.button/button.component';
import { MobilePlaceholder, ShimmerMobilePlaceholder } from '@lp-root/src/components/mol.mobile-placeholder/mobile-placeholder.component';
import { ShimmeStartSection } from '@lp-root/src/components/mol.section-start/section-start.component';
import WebviewLayout from '@lp-root/src/components/org.layout/webview-layout.component';
import { File } from '@lp-root/src/data/graphql/local-graphql-types';
import { IAdvertisementSectionProps } from '@lp-root/src/modules/telehealth/components/advertisement-section.component';
import { useHealthCarersTelehealth } from '@lp-root/src/modules/telehealth/health-carers-telehealth.hook';
import { NextAppointmentPage } from '@lp-root/src/modules/telehealth/next-appointment-page/next-appointment-page.component';
import { StartPage } from '@lp-root/src/modules/telehealth/start-page';
import { PageProps } from '@lp-root/src/utils/local-types';
import { Col, Grid, Row, Separator } from '@web/atomic';
import { LoadingState } from '@web/atomic/legacy/obj.loading-state';
import { NativeHelper } from '@web/data/native.helper';
import { useGetUsingMessenger } from '@web/data/use-get-using-messenger.hook';
import { hasWindow } from '@web/utils/platform';
import * as React from 'react';
import { useEffect, useState } from 'react';

/**
 *
 * @deprecated: This page is deprecated and will be removed after b2c realease. inicio-v2 is the new page.
 */
const InicioPage: React.FunctionComponent<PageProps<unknown, { laura: File }>> = () => {
  const [showMaxAppointmentCountReached, setShowMaxAppointmentCountReached] = useState<boolean>(false);
  const [advertisementInfo, setAdvertisementInfo] = useState<{ title: string; description: string; callToAction: string }>(null);
  useEffect(() => {
    const { appointment_count, max_appointment_count, ad_call_to_action, ad_description, ad_title } =
      (NativeHelper.extraData as NativeConfigWebviewExtraDataTelehealthInfo) ?? {};
    setShowMaxAppointmentCountReached(max_appointment_count && appointment_count && max_appointment_count === appointment_count);
    setAdvertisementInfo({ title: ad_title, callToAction: ad_call_to_action, description: ad_description });
  }, [NativeHelper.extraData]);

  const { data, error, loading } = useGetUsingMessenger<GetNextAppointmentRequest, GetNextAppointmentResponse>({
    url: GetNextAppointmentUrl,
    params: { user_id: NativeHelper.userId },
  });
  const nextAppointment = data?.next_appointment[0];

  const hcp = useHealthCarersTelehealth();

  if (showMaxAppointmentCountReached) {
    return (
      <WebviewLayout hideThemeChanger skipMessengerExtensionLoad>
        <GradientBackground level={0}>
          <Grid>
            <Row center="xs">
              <Col xs={12} sm={12} md={5} lg={4}>
                <Separator />
                <MobilePlaceholder emoji="😐" description={`Você não tem mais consultas disponíveis para agendamento.`}>
                  <GatsbyButton
                    expanded
                    onClick={() => {
                      const msg: CustomWebviewMessageForTeleatendimentoInicio = {
                        type: 'custom',
                        id: 'more_info_max_appointment_reached',
                      };
                      NativeHelper.postMessage(msg);
                    }}
                  >
                    Obter mais consultas
                  </GatsbyButton>
                </MobilePlaceholder>
              </Col>
            </Row>
          </Grid>
        </GradientBackground>
      </WebviewLayout>
    );
  }

  const advertisement: IAdvertisementSectionProps = advertisementInfo && {
    ...advertisementInfo,
    onClick: () => {
      const msg: CustomWebviewMessageForTeleatendimentoInicio = {
        type: 'custom',
        id: 'ad_click',
      };
      NativeHelper.postMessage(msg);
    },
  };

  return (
    <WebviewLayout hideThemeChanger skipMessengerExtensionLoad>
      <GradientBackground level={0}>
        <LoadingState loading={loading} error={!!error} data={!!data}>
          <LoadingState.Shimmer>
            <Grid>
              <Row mb>
                <Col lg={4} md={5} sm={9} xs={12}>
                  <ShimmeStartSection noMarginTop />
                  <ShimmerMobilePlaceholder />
                </Col>
              </Row>
            </Grid>
          </LoadingState.Shimmer>
          <LoadingState.Error>
            <Grid>
              <Row center="xs">
                <Col xs={12} sm={12} md={5} lg={4}>
                  <Separator />
                  <MobilePlaceholder emoji="🧐" description="Erro ao pegar seus dados. Clique no botão abaixo para tentar novamente.">
                    <GatsbyButton
                      expanded
                      onClick={() => {
                        if (hasWindow()) window.location.reload();
                      }}
                    >
                      Recarregar página
                    </GatsbyButton>
                  </MobilePlaceholder>
                </Col>
              </Row>
            </Grid>
          </LoadingState.Error>

          {nextAppointment ? (
            <NextAppointmentPage
              // This component will be deleted soon, that's why we don't handle onViewProfileClick
              onViewProfileClick={console.log}
              url={nextAppointment?.url}
              date={nextAppointment?.date}
              // This component will be deleted soon, that's why I there's a cast to any
              hcp={hcp.find((h) => h.healthCarerId === nextAppointment.health_carer_id) as any}
              onMoreInfoClick={() => {
                const msg: CustomWebviewMessageForTeleatendimentoInicio = {
                  type: 'custom',
                  id: 'more_info_appointment',
                };
                NativeHelper.postMessage(msg);
              }}
              advertisement={advertisement}
            />
          ) : (
            <StartPage
              // This component will be deleted soon, that's why we don't handle onViewProfileClick
              onViewProfileClick={console.log}
              appointmentCount={data?.appointment_count}
              onScheduleClick={(hcpId: string) => {
                const msg: CustomWebviewMessageForTeleatendimentoInicio = {
                  type: 'custom',
                  id: 'schedule',
                  data: { hcpId, hcpName: hcp.find((h) => h.healthCarerId === hcpId).name },
                };
                NativeHelper.postMessage(msg);
              }}
              onMoreInfoClick={() => {
                const msg: CustomWebviewMessageForTeleatendimentoInicio = {
                  type: 'custom',
                  id: 'more_info_telehealth',
                };
                NativeHelper.postMessage(msg);
              }}
              // This component will be deleted soon, that's why I there's a cast to any
              hcp={hcp as any}
              advertisement={advertisement}
            />
          )}
        </LoadingState>
      </GradientBackground>
    </WebviewLayout>
  );
};

export default InicioPage;
