import React from 'react';
import { isDevelopment } from '@lp-root/src/utils/environment';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Body } from '@web/atomic/legacy/atm.typography';

export interface HealthCarerTelehealthInfo {
  description: JSX.Element;
  healthCarerId: string;
  professionId: string;
  imgSrc?: IGatsbyImageData;
  name: string;
  tags: string[];
}

export const useHealthCarersTelehealth = (): HealthCarerTelehealthInfo[] => {
  const data = useStaticQuery(graphql`
    {
      caroline: file(relativePath: { eq: "psico-caroline.png" }) {
        childImageSharp {
          gatsbyImageData(width: 136, layout: FIXED, placeholder: BLURRED)
        }
      }
      talita: file(relativePath: { eq: "talita-lobo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 136, layout: FIXED, placeholder: BLURRED)
        }
      }
      juliane: file(relativePath: { eq: "psico-juliane.png" }) {
        childImageSharp {
          gatsbyImageData(width: 136, layout: FIXED, placeholder: BLURRED)
        }
      }
      laura: file(relativePath: { eq: "laura2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 136, layout: FIXED, placeholder: BLURRED)
        }
      }
      vanisse: file(relativePath: { eq: "psico-vanisse.png" }) {
        childImageSharp {
          gatsbyImageData(width: 136, layout: FIXED, placeholder: BLURRED)
        }
      }
    }
  `);
  // # INSTRUCTIONS
  // if you want to show more health-carers, add them to this array
  const hcp: HealthCarerTelehealthInfo[] = [
    {
      name: 'Caroline Rodrigues ',
      imgSrc: data.caroline.childImageSharp.gatsbyImageData,
      healthCarerId: getHcpIdForEnv('1FBp6YeY29chA9pirwULsfWLmyX2'),
      professionId: 'CRP 05/55543',
      tags: [
        'Depressão',
        'Alterações de humor',
        'Transtornos de ansiedade',
        'Saúde mental',
        'Rotina multidisciplinar na saúde mental',
        'Inteligência emocional',
      ],
      description: <Body center>Gestalt-Terapia e TCC </Body>,
    },
    {
      name: 'Juliane Santos ',
      imgSrc: data.juliane.childImageSharp.gatsbyImageData,
      healthCarerId: getHcpIdForEnv('xnkN2JZePmfA77trCjO2gdo8F082'),
      professionId: 'CRP 05/55694',
      tags: ['Ansiedade', 'Conflitos amorosos', 'Alterações de humor', 'Auto-estima'],
      description: (
        <>
          <Body center>Gestalt Terapia e Psicoterapia breve</Body>
        </>
      ),
    },
    {
      name: 'Laura Castro',
      imgSrc: data.laura.childImageSharp.gatsbyImageData,
      healthCarerId: getHcpIdForEnv('6xLs8W1Zvue9UHGHng46T1ZpDt62'),
      professionId: 'CRP 06/83226',
      tags: ['Alterações de humor', 'Insônia', 'Transtornos do sono', 'Doenças crônicas', 'Desmame de medicações', 'Psicanálise'],
      description: (
        <>
          <Body center>Psicologia da saúde e Psicologia do sono</Body>
        </>
      ),
    },
    {
      name: 'Talita Cepas Lobo',
      imgSrc: data.talita.childImageSharp.gatsbyImageData,
      healthCarerId: getHcpIdForEnv('QwcNKGJfQxg9J6SPL8eHDqm0Ih13'),
      professionId: 'CRP 06/123237',
      tags: ['Alterações de humor', 'Doenças crônicas', 'Tabagismo', 'Psicanálise'],
      description: (
        <>
          <Body center>Psicologia da saúde e Psicoterapia breve</Body>
        </>
      ),
    },
    {
      name: 'Vanisse Barros',
      imgSrc: data.vanisse.childImageSharp.gatsbyImageData,
      healthCarerId: getHcpIdForEnv('g5BunaOWasguUinhS1nwqKRlyiQ2'),
      professionId: 'CRP 05/53741',
      tags: ['Autoconhecimento', 'Autoestima', 'Ansiedade', 'Depressão', 'Orientação Psicológica', 'Relacionamentos'],
      description: (
        <>
          <Body center> Psicanálise e Neuropsicologia</Body>
        </>
      ),
    },
  ];

  return hcp;
};

const getHcpIdForEnv = (hcpId: string) => (isDevelopment ? 'Y4nwCSH5vnRtivma9A0AAc9SJS13' : hcpId);
